import {PERM_FREE} from "@/modules/security/services/SecurityService";
import {authGuard} from "@/auth/authGuard";

export default [{
    path: '/:bot/user-history',
    name: 'History',
    meta: { permission: PERM_FREE },
    beforeEnter: authGuard,
    component: () => import('../views/History.vue')
  }

]