import {PERM_VIEW_LIBRARY} from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [
  {
    path: '/:bot/ivdr/:projectId',
    name: 'Extract Explorer',
    meta: {permission: PERM_VIEW_LIBRARY},
    beforeEnter: authGuard,
    component: () => import('../views/ExtractExplorer.vue')
  }
]