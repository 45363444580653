import {authGuard} from "@/auth/authGuard";
import { PERM_VIEW_GENERAL_INFO } from '@/modules/security/services/SecurityService'

export default [
  {
    path: '/:bot/how-to',
    name: 'HowTo',
    meta: {permission:PERM_VIEW_GENERAL_INFO},
    alias: '',
    beforeEnter: authGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HowTo.vue')
  }
]