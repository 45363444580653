<template>
  <div>
    <b-form-select v-model="$i18n.locale" :options="currentLocales" @change="switchLocale"></b-form-select>
  </div>
</template>

<script>

export default {
  name: 'LanguageSwitcher',
  methods: {
    switchLocale(locale) {
      localStorage.setItem("locale", locale)
    }
  },
  created() {
    var locales = this.$i18n.availableLocales
    this.currentLocales = locales.map((locale) => {
      return { value: locale, text: locale.toUpperCase() }
    })
  }
}
</script>

<style lang="css" scoped>
</style>