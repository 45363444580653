import Repository from '@/modules/common/utils/Repository.js'
import store from '@/store'

export default {
  save(project, localFile = false, selectedTreatment = null) {
    return Repository.dbService.post('/project/save', project, {
      params: {
        bot_name: store.getters.getInstance,
        local_file: localFile,
        treatment_id: selectedTreatment
      }
    })
  },
  saveManualMode(project, zipFile) {
    let formData = new FormData()
    formData.append('file', zipFile)
    formData.append('project', JSON.stringify(project))
    let headers = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return Repository.dbService.post(`/project/save_manual_mode?bot_name=${store.getters.getInstance}`, formData, headers)
  },
  search(extraFilters = {}) {
    extraFilters['bot_name'] = store.getters.getInstance
    return Repository.dbService.get('/project/search' , { params: extraFilters })
  },
  get(projectId, botId, getOldVersions = false) {
    return Repository.dbService.get('/project/get', {
      params: {
        bot_name: botId,
        project_id: projectId,
        get_old_versions: getOldVersions
      }
    })
  },
  remove(projectId) {
    return Repository.dbService.delete('/project/delete', {params: {
        bot_name: store.getters.getInstance,
        project_id: projectId
      }
    })
  },
  removeDocument(projectId, docId, docTitle) {
    return Repository.dbService.delete('/project/delete_document', {params: {
        bot_name: store.getters.getInstance,
        project_id: projectId,
        doc_id:  docId,
        title: docTitle
      }
    })
  },
  rename(projectRenamed) {
    return Repository.dbService.post(`/project/rename?bot_name=${store.getters.getInstance}`, projectRenamed)
  },
  getSlimProjects(){
    return Repository.dbService.get('/project/list_id_names', {
      params: {
        bot_name: store.getters.getInstance,
      }
    })
  }
}