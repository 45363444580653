import {
  GET_INSTANCE_PERMISSIONS,
  SET_CURRENT_INSTANCE
} from '@/modules/security/store/actions/PermissionsActions'
import SecurityService from '@/modules/security/services/SecurityService'
import { PERM_MODIFY_LIBRARY } from '@/modules/security/services/SecurityService'
import store from '@/store'
import router from '@/router'

function havePermission(permissions, permissionConst) {
  return permissions.indexOf(permissionConst) != -1
}

function applyInstanceConfiguration(instance) {
  let secureContent = instance.config.secure_content === 'true'
  let overwriteProtection = (instance.permissions.indexOf("PERM_OVER_CONTENT_PROTECT") > -1)
  let copyEnabled = Boolean(!secureContent | overwriteProtection)
  document.oncontextmenu = () => copyEnabled
  document.oncopy = () => copyEnabled
}

const state = {
  noInstance:false,
  currentInstanceName:'',
  currentInstancePath:'',
  currentInstanceForbiddenTags:[],
  currentInstanceAvatarUrl: '',
  currentInstanceAvatarDestinationUrl: '',
  currentInstancePermissions:[],
  currentInstancePermissionInitialized: false,
  currentInstancePermissionInProgress: false
}
const getters = {
  noInstance: state => state.noInstance,
  hasSelectedInstance: state => state.currentInstanceName !='',
  getInstance: state => state.currentInstanceName,
  getInstancePermissions: state => state.currentInstancePermissions,
  getInstanceForbiddenTags: state => state.currentInstanceForbiddenTags,
  getInstanceAvatarUrl: state => state.currentInstanceAvatarUrl,
  getInstanceAvatarDestinationUrl: state => state.currentInstanceAvatarDestinationUrl,
  isCurrentInstancePermissionInitialized : state => state.currentInstancePermissionInitialized,
  isCurrentInstancePermissionInProgress : state => state.currentInstancePermissionInProgress,
  canModifyLibrary: state => havePermission(state.currentInstancePermissions, PERM_MODIFY_LIBRARY)
}
const actions = {
  [SET_CURRENT_INSTANCE] : ({commit}, {instanceName, path}) => {
    let instance = undefined

    if (store.getters.getCurrentUser.bots.length === 1) {
      instance = store.getters.getCurrentUser.bots[0]
    } else {
      store.getters.getCurrentUser.bots.forEach(bot => {
        if(bot.bot_name == instanceName) {
          instance = bot
        }
      })
    }
    if (instance == undefined) {
      // check if the previous redirection does not go to /settings/my-instances
      if (path && path != '/settings/my-instances')
        router.push('/settings/my-instances')
    } else {
      commit('setCurrentInstance', instance)
      if (path == '/' + instanceName + '/') {
        if (instance.homepage != undefined && instance.homepage != '') {
          router.push('/' + instance.bot_name + instance.homepage)
        }
      }
    }
  },
  [GET_INSTANCE_PERMISSIONS] : ({commit}) => {
    //get the permissions
    //todo do something if user does not have selected an instance:
    return SecurityService.getPermissions().then(
      response => {
        commit('setCurrentInstancePermissions', response.data.permissions)
        return Promise.resolve(response)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  setCurrentInstance(state, instance){
    if (instance == undefined) {
      state.noInstance = true
    } else {
      state.currentInstanceName = instance.bot_name
      state.currentInstancePath = instance.bot_path
      state.currentInstancePermissions = instance.permissions
      state.currentInstanceForbiddenTags = instance.forbidden_tags
      state.currentInstancePermissionInProgress=false
      state.currentInstancePermissionInitialized=true
      state.currentInstanceAvatarUrl=instance.config.avatar_url
      state.currentInstanceAvatarDestinationUrl=instance.config.avatar_destination_url
      // Config secure content
      applyInstanceConfiguration(instance)
    }
  },
  setCurrentInstancePermissions(state, permissions){
    state.currentInstancePermissionInProgress=false
    state.currentInstancePermissionInitialized=true
    state.currentInstancePermissions=permissions
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
