// import Repository from '@/modules/common/utils/Repository.js'
import axios from 'axios'
const authenticatorHost = process.env.VUE_APP_AUTHENTICATOR_HOST
const dbServiceBaseUrl = process.env.VUE_APP_DB_SERVICE_API_URL

export default {
  getToken(authCode) {
    // this request doesnt use the Repository beacause it needs not being intercepted
    return axios.get(dbServiceBaseUrl + 'user/get_token?auth_code=' +  authCode).then(
      response => {
        if (!response.error && response.data.token != null) {
          var token = response.data.token
          return Promise.resolve(token)
        } else {
          console.log('could not obtain a token from this auth code')
          return Promise.reject('could not obtain a token from this auth code')
        }

      },
      error => {
        console.log(error)
        return Promise.reject('could not obtain a token from this auth code')
      }
    )
  },
  getTokenFromResponse(response){
    var token = response.headers.authorization
    if (token.indexOf('Bearer') > -1) {
      token = token.split(' ')[1]
    }
    return token
  },
  toLogin(nextUrl) {
    window.location = authenticatorHost + '/?next=' + nextUrl
  }

}