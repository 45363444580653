import {PERM_MODIFY_LIBRARY, PERM_VIEW_LIBRARY, PERM_VIEW_PROJECTS} from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [
  {
    path: '/:bot/ingestion-project/create',
    name: 'IngestionProjectCreate',
    meta: {permission: PERM_MODIFY_LIBRARY},
    beforeEnter: authGuard,
    component: () => import('../views/NewIngestionProject')
  },
  {
    path: '/:bot/projects',
    name: 'MyProjects',
    meta: {permission: PERM_VIEW_PROJECTS},
    beforeEnter: authGuard,
    component: () => import('../views/MyProjects')
  },
  {
    path: '/:bot/projects/:projectId',
    name: 'Project',
    meta: {permission: PERM_VIEW_LIBRARY},
    beforeEnter: authGuard,
    component: () => import('../views/Project')
  },
  {
    path: '/:bot/projects/:projectId/:fileId',
    name: 'File',
    meta: {permission: PERM_MODIFY_LIBRARY},
    beforeEnter: authGuard,
    component: () => import('../views/File')
  }
]