import {
  PERM_VIEW_QA,
  PERM_MODIFY_QA,
  PERM_FREE
} from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [{
    path: '/:bot/my-apps/knowledge/ask',
    name: 'Ask',
    meta: { permission: PERM_FREE , title: 'Sorcero Intelligent Search'},
    beforeEnter: authGuard,
    component: () => import('../views/Ask.vue')
  },
  {
    path: '/:bot/my-apps/knowledge/ask_results',
    name: 'AskResults',
    meta: { permission: PERM_FREE , title: 'Sorcero Intelligent Search'},
    beforeEnter: authGuard,
    component: () => import('../views/AskResults.vue')
  }, 
  {
    path: '/:bot/my-apps/knowledge/questions',
    name: 'QAPairs',
    meta: { permission: PERM_VIEW_QA , title: 'Sorcero Questions and Answers'},
    beforeEnter: authGuard,
    component: () => import('../views/QAPairsList.vue')
  },{
    path: '/:bot/my-apps/knowledge/question/create',
    name: 'CreateQAPair',
    meta: { permission: PERM_MODIFY_QA },
    beforeEnter: authGuard,
    component: () => import('../views/CreateQAPair.vue')
  }, {
    path: '/:bot/my-apps/knowledge/question/:id',
    name: 'Question',
    meta: { permission: PERM_VIEW_QA },
    beforeEnter: authGuard,
    component: () => import('../views/QuestionDetail.vue')
  }, {
    path: '/question/:bot/:questionId',
    name: 'QuestionLink',
    meta: { permission: PERM_VIEW_QA },
    component: () => import('@/modules/common/views/RedirectCuAppLinks.vue')
  }
]