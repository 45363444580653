import {PERM_VIEW_GENERAL_INFO} from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [
  {
    path: '/:bot/recipe-library',
    name: 'Recipe',
    meta: {permission: PERM_VIEW_GENERAL_INFO},
    beforeEnter: authGuard,
    component: () => import('../views/RecipeLibrary')
  }
]