<template>
  <div id="app" :class="viewIsEta ? 'eta-container' : ''">
    <navbar/>
    <b-row class="container col-12">
      <side-nav/>
      <router-view :key="$route.fullPath" />
    </b-row>
  </div>
</template>

<script>
import SideNav from '@/modules/common/components/SideNav.vue'
import Navbar from "@/modules/common/components/Navbar";

const DEFAULT_TITLE = 'Sorcero Platform'
export default {
  components: {
    'side-nav': SideNav,
    'navbar': Navbar
  },
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        document.title = to.meta.title || DEFAULT_TITLE
      }
    }
  },
  computed: {
    viewIsEta() {
      return this.$route.meta.isEtaBot == true
    }
  },
}
</script>


<style lang="scss" >

div#app {
  font-family: OpenSans, Avenir, Helvetica, Arial, sans-serif, Symbola;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div#app.eta-container {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto" Helvetica, sans-serif, Symbola !important;
}

@supports ( font-variation-settings: normal ) {
	div#app.eta-container {
		font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", Helvetica, sans-serif, Symbola !important;
	}
} 

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.eta-container {
  width: 100% !important;
}
</style>