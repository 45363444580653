import { PERM_MODIFY_SETTINGS, PERM_FREE, PERM_MODIFY_USER_SETTINGS } from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [{
    path: '/:bot/settings',
    name: 'Settings',
    meta: { permission: PERM_MODIFY_SETTINGS , title: 'Sorcero Settings' },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabMyProfile.vue')
  }, {
    path: '/:bot/settings/my-profile',
    name: 'SettingsMyProfile',
    meta: { permission: PERM_MODIFY_USER_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabMyProfile.vue')
  }, {
    path: '/settings/my-instances',
    name: 'SettingsMyInstancesWithoutBot',
    meta: { permission: PERM_FREE },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabMyInstances.vue')
  }, {
    path: '/:bot/settings/my-instances',
    name: 'SettingsMyInstances',
    meta: { permission: PERM_FREE },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabMyInstances.vue')
  }, {
    path: '/:bot/settings/instance',
    name: 'SettingsInstance',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/InstanceSettings.vue')
  }, {
    path: '/:bot/settings/utility-sets',
    name: 'SettingsUtilitySets',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/UtilitySets.vue')
  }, {
    path: '/:bot/settings/roles-and-permission',
    name: 'SettingsRolesAndPermission',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../views/RolesAndPermission.vue')
  },{
    path: '/:bot/settings/user-admin',
    name: 'SettingsUserAdminSettings',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabUserAdminSettings.vue')
  },{
    path: '/settings/user-admin',
    name: 'SettingsUserAdminSettings',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/TabUserAdminSettings.vue')
  },{
    path: '/:bot/settings/search-settings',
    name: 'SettingsSearch',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/SearchSettings.vue')
  },{
    path: '/:bot/settings/security',
    name: 'SecuritySettings',
    meta: { permission: PERM_MODIFY_SETTINGS },
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "about" */ '../components/SecurityTab.vue')
  }
]