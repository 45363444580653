import { PERM_VIEW_GENERAL_INFO } from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default {
    path: '/:bot/utility-sets',
    name: 'Utility',
    meta: {permission: PERM_VIEW_GENERAL_INFO},
    beforeEnter: authGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UtilitySets.vue')
}