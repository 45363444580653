import moment from 'moment'

export default {
  formatTime(value) {
    if (!value) return ''
    return moment.utc(value).local().fromNow()
  },

  titleUpperCase(value) {
    if (!value) return ''
    value = value.toString()
    return value.toUpperCase()
  },

  copyToClipboard(text) {
    const textElem = document.createElement('textarea')
    textElem.value = text
    document.body.appendChild(textElem)
    textElem.select()
    document.execCommand('copy')
    document.body.removeChild(textElem)
  },

  goToExternalUrl(url) {
    window.location.replace(url)
  }
}