import axios from 'axios'
// import AuthenticationService from '@/modules/security/services/AuthenticationService.js'
import store from '@/store'
// import router from '@/router'
import {getInstance} from "@/auth";

const dbServiceBaseUrl = process.env.VUE_APP_DB_SERVICE_API_URL
const sorceroIngestionServiceUrl = process.env.VUE_APP_INGESTION_SERVICE_API_URL


// to allow the backend setting cookies in the frontend (from a different domain), requsts
// must have the withCredentials property set to true
var dbServiceInstance = axios.create({ baseURL: dbServiceBaseUrl, withCredentials:true})

dbServiceInstance.interceptors.request.use(
  request => {
    return new Promise(((resolve)=> {
      getInstance().getTokenSilently().then(token => {
        request.headers['Authorization'] = `Bearer ${token}`
        return resolve(request);
      })
    }))

  }
)

//TODO: resolve this for all APIs
var ingestionServiceInstance = axios.create({ baseURL: sorceroIngestionServiceUrl})

ingestionServiceInstance.interceptors.request.use(
    request => {
      if (request.headers['Authorization'] == undefined) {
        ingestionServiceInstance.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.token
        request.headers['Authorization'] = 'Bearer ' + store.getters.token
      }
      return Promise.resolve(request);
    }
  )

export default {
  dbService: dbServiceInstance,
  sorceroIngestionService: ingestionServiceInstance
}