import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/modules/security/store/AuthStore'
import Permissions from '@/modules/security/store/PermissionsStore'
import User from '@/modules/security/store/UserStore'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Auth,
    Permissions,
    User
  },
  strict: debug
})
