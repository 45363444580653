/* eslint-disable no-unused-vars */
import AuthenticationService from '@/modules/security/services/AuthenticationService.js'
import Repository from '@/modules/common/utils/Repository.js'
import {TOKEN_UPDATE ,LOGOUT, LOGIN, GET_TOKEN} from '@/modules/security/store/actions/AuthActions'
import {GET_USER} from '@/modules/security/store/actions/UserActions'
import store from '@/store'
import router from '@/router'
import cookie from 'vue-cookies'

const state ={
  token: localStorage.getItem('user-token') || null,
  tokenObtentionInProgress:false,
  status: ''
}
const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  token: state => {
    mutations.getTokenSuccess(state, localStorage.getItem('user-token') || null)
    return state.token
  },
  isGettingToken: state => state.tokenObtentionInProgress
}
const actions = {
  [GET_TOKEN] : ({commit}, authCode) => {
    commit('getTokenStart')
    return AuthenticationService.getToken(authCode).then(
      token => {
        return store.dispatch(LOGIN, token).then(
          function () {return Promise.resolve(), router.push('/how-to')}
        )
      }, error => {
        console.log(error)
        commit('getTokenFailure')
        return Promise.reject(error);

      }
    )
  },

  [LOGIN] : ({commit}, token) => {
    return store.dispatch(TOKEN_UPDATE, token).then(
      function() {
        return store.dispatch(GET_USER).then(
          function () {return Promise.resolve()}
        )
      }
    )
  },

  [TOKEN_UPDATE] : ({commit}, token) => {
    return new Promise(
      (resolve, reject) => {
        localStorage.setItem('user-token', token)
        let headerName ='Authorization'
        let headerValue = 'Bearer ' + token
        Repository.dbService.defaults.headers.common[headerName] = headerValue
        delete Repository.dbService.defaults.headers.common['Authorization']
        commit('tokenRefreshSuccess', token)
        resolve(token)
      }
    )
  }
}

const mutations = {
  getTokenStart(state){
    state.tokenObtentionInProgress=true
    state.token = ''
  },
  getTokenSuccess(state, token){
    state.tokenObtentionInProgress=false
    state.token=token
  },
  getTokenFailure(state, token){
    state.tokenObtentionInProgress=false
    state.token=''
  },
  tokenRefreshSuccess(state, token){
    state.token=token
  },

  logoutSuccesss(state){
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
