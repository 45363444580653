import { getInstance } from "./index";
// import Vuex from 'vuex'
import SecurityService from '@/modules/security/services/SecurityService'
import store from '@/store'

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  function proceed() {
    // If the user is authenticated, continue with the route
    if (!authService.isAuthenticated) {
      // Otherwise, log in
      authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
    } else {
      if (store.state.User.user.bots.length > 0 ) {
        return SecurityService.chekRoutePermission(to, next)
      } else {
        // Watch
        store.watch((state) => state.User.user.bots , () => {
          if (store.state.User.user.bots.length > 0 ) {
            return SecurityService.chekRoutePermission(to, next)
          }
        })
      }
    }
  }

  // If loading has already finished (the auth SDK Loading),
  // and user's bots are already loaded,
  // check our auth state using `proceed()`
  if (!authService.loading && store.state.User.user.bots.length > 0 ) {
    return proceed();
  } else {
    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
      if (loading === false) {
        return proceed();
      }
    });
  }
};