/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTelInput from 'vue-tel-input'
import './styles/import.scss'
import i18n from './i18n'
import moment from 'moment'
import {LOGIN, GET_TOKEN} from '@/modules/security/store/actions/AuthActions'
import AuthenticationService from '@/modules/security/services/AuthenticationService'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
import VueTagsInput from '@johmun/vue-tags-input';
import VueRecord from '@codekraft-studio/vue-record'
// main.js
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'


// Import the plugin here
import { Auth0Plugin } from "./auth";

// Auth0 configuration
const domain = process.env.VUE_APP_AUTHZERO_DOMAIN
const clientId = process.env.VUE_APP_CLIENT_ID
const audience = process.env.VUE_APP_AUDIENCE

const urlParams = new URLSearchParams(window.location.search);
const $ = require('jquery')
window.$ = $
const enforceHttps = process.env.VUE_APP_ENFORCE_HTTPS == 'true'

if (enforceHttps && window.location.protocol !== 'https:') {
  console.log('redirect to https')
  window.location = String(window.location).replace('http://', 'https://')
}

console.log('initialising vue application')
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueAxios, axios)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.use(VueTelInput)
Vue.use(VueRecord)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
// Markdown Editor
VueMarkdownEditor.use(vuepressTheme)
Vue.use(VueMarkdownEditor)
VueMarkdownEditor.lang.use('en-US', enUS)
VueMarkdownEditor.use(createEmojiPlugin())

Vue.component('vue-tags-input', VueTagsInput)
Vue.component('vueSlider', VueSlider)
Vue.component('v-select', vSelect)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('multiselect', Multiselect)


Vue.config.productionTip = false

Object.defineProperty( Vue.prototype, '$moment', { value: moment })

new Vue({
  store: store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
