/* eslint-disable no-unused-vars */

import SecurityService from "@/modules/security/services/SecurityService";
import {GET_USER} from "@/modules/security/store/actions/UserActions";


const state ={
  user: {
    fullName:'',
    userid:'',
    bots:[]
  }
}
const getters = {
  getCurrentUser: state => state.user
}
const actions = {
  [GET_USER] : ({commit}) => {
    let default_bot = process.env.VUE_APP_DEFAULT_INSTANCE
    return SecurityService.getUser().then(
      response => {
        commit('setCurrentUser', response.data)
        return Promise.resolve(response)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  setCurrentUser(state, user) {
    state.user.fullName = user.fullName
    state.user.userid = user.userId
    state.user.bots = user.bots
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
