<template>
<div class="full-width-container">
  <b-navbar toggleable="lg" type="light" variant="faded">
    <b-navbar-brand v-on:click="goToLanding" class="image-left-container">
        <img  class="instance-image-navbar cursor-pointer"  v-if="$store.getters.getInstanceAvatarUrl" :src="$store.getters.getInstanceAvatarUrl">
        <img  class="cursor-pointer" width="40" height="auto" v-else src="/assets/images/RGB_Sorcero_Secondary_Logo_Black.png">
    </b-navbar-brand>
    <b-row  v-if="$route.name=='MyProjects'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.myProjects') }}</span>
      <router-link class="ml-35" v-if="this.$store.getters.canModifyLibrary" :to="'/' + $store.getters.getInstance + '/ingestion-project/create'">
        <img  src="/assets/images/icon_add.svg">
        <span class="fz-14 my-projects-link">{{ $t('general.navbar.new') }}</span>
      </router-link>
    </b-row>
    <b-row  v-if="$route.name=='Project'">
      <span class="fz-16 navbar-view-label">{{ $t('general.navbar.myProjects') }}</span>
      <b-form-select v-model="selectedProject"  @change="pushProjectRoute" :options="projects" class="btn-secondary-myproj navbar-projects" ></b-form-select>
    </b-row>
    <b-row  v-if="$route.name=='Library'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.myContent') }}</span>
      <router-link class="ml-35" v-if="this.$store.getters.canModifyLibrary" :to="'/' + $store.getters.getInstance + '/ingestion-project/create'">
        <img class="my-projects-link"  src="/assets/images/icon_li_ingestion.svg">
        <span class="fz-14 my-projects-link">{{ $t('general.navbar.addIngestionPipeline') }}</span>
      </router-link>
    </b-row>
    <b-row  v-if="$route.name=='Recipe'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.recipeLibrary') }}</span>
    </b-row>
    <b-row  v-if="$route.name=='Settings'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.settings') }}</span>
    </b-row>
    <b-row  v-if="$route.name=='Ask'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.ask') }}</span>
    </b-row>
    <b-row  v-if="$route.name=='My Applications'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.myapps') }}</span>
    </b-row>
    <b-row  v-if="$route.name=='QAPairs'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.qapairs') }}</span>
    </b-row>
    <b-row  v-if="$route.name=='Medical Guideline Management'">
      <span class="fz-16" :class="{'ml-25': is_desktop}" >{{ $t('general.navbar.mgm') }}</span>
    </b-row>

    <b-navbar-toggle :target="is_desktop ? 'nav-collapse-desktop' : 'nav-collapse-mobile'"></b-navbar-toggle>

    <b-sidebar id="nav-collapse-mobile" class="pa-5" right shadow>

      <div class="mb-4 pt-0 ta-center" v-if="!$auth.loading">
        <div v-if="$auth.user" class="card-body py-0 text-center">
          <img :class="is_desktop ? '' : 'mobile-picture'" class="rounded-circle w-50 mb-3" :src="$auth.user.picture"/>
          <p class="mb-0">{{ $auth.user.name }}</p>
          <!--<p>{{ $auth.user.email }}</p>-->
        </div>
      </div>

      <router-link v-slot="{route, href, navigate}"  class="" :to="'/' + $store.getters.getInstance + '/how-to'">
          <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
            <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_howto.svg">
            <p class="txt-black openSans-400 fz-12">{{ $t('general.sidenav.howto') }}</p>
          </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/projects'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_myprojects.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">My Projects</p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/library'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_mycontent.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">My Content</p>
        </a>
      </router-link>
      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/my-apps'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_myapps.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">
            {{ $t('myApps.title') }}
          </p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="askRoute">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon ask-icon" :src="'/assets/images/icon_knowledge_ask.svg'">
          <p class="openSans-400 fz-12 menu-mobile-text">
            {{ $t('general.navbar.ask') }}
          </p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/utility-sets'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_utilitysets.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">{{ $t('utilitySets.title') }}</p>
        </a>
      </router-link>
      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/recipe-library'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_recipelibrary.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">{{ $t('recipeLibrary.title') }}</p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/dashboard'">
        <a  class="mobile-drawer-item" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_dashboard.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">{{ $t('general.sidenav.dashboard') }}</p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/user-history'">
        <a  class="mobile-drawer-item mb-20" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/user-history.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">
            {{ $t('settings.menu.tab.userHistory.title') }}
          </p>
        </a>
      </router-link>

      <router-link v-slot="{route, href, navigate}" class="" :to="'/' + $store.getters.getInstance + '/settings'">
        <a  class="mobile-drawer-item mb-20" :href="href" @click="navigate" v-show="hasPermission(route.meta.permission)">
          <img class="menu-mobile-icon" src="/assets/images/icon_leftnav_settings.svg">
          <p class="openSans-400 fz-12 menu-mobile-text">
            {{ $t('settings.menu.tab.settings.title') }}
          </p>
        </a>
      </router-link>

      <b-button  class="d-block button-secondary mx-auto mobile-logout-button" size="sm" v-on:click="logout">{{ $t('general.navbar.logout') }}</b-button>
    </b-sidebar>

    <b-collapse id="nav-collapse-desktop" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-row :class="is_desktop ? 'd-flex': 'd-none'" class="justify-evenly">
          <template v-slot:button-content>
            <b-button v-if="canViewMyInstances" size="sm" class="my-2 navbar-icon my-sm-0 icon-empty">
              <img src="/assets/images/icon_navbar_profile.svg">
            </b-button>
          </template>
        </b-row>
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <b-button v-if="canViewMyInstances" size="sm" class="my-2 navbar-icon my-sm-0 icon-empty">
              <img src="/assets/images/icon_navbar_profile.svg">
              <p class="side-bar-item-title openSans-400 fz-12 menu-mobile-text d-none">User</p>
            </b-button>
          </template>
          <div v-if="!$auth.loading">
            <div v-if="$auth.user" class="card-body pb-0 text-center">
              <img class="rounded-circle w-50 mb-3" :src="$auth.user.picture"/>
              <p class="mb-0">{{ $auth.user.name }}</p>
            </div>
            <b-dropdown-item size="sm" v-on:click="logout">{{ $t('general.navbar.logout') }}</b-dropdown-item>
          </div>
          <b-dropdown-item v-if="multipleInstancesAssociated" size="sm" v-on:click="goToMyInstances">{{ $t('general.navbar.myInstances') }}</b-dropdown-item>
          <b-dropdown-item v-if="canViewSettings" size="sm" v-on:click="goToSettings">Settings</b-dropdown-item>
          <b-dropdown-item v-if="canViewSettings" size="sm" v-on:click="goToUserHistory">User History</b-dropdown-item>

<!--          <b-dropdown-divider></b-dropdown-divider>-->
          <b-dropdown-item class="d-none">
            <language-switcher class="d-none"/>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import store from '@/store'
import LanguageSwitcher from '@/modules/common/components/LanguageSwitcher.vue'
import ProjectService from '@/modules/projects/services/ProjectService'
import {mapState} from 'vuex'
import {PERM_FREE, PERM_MODIFY_SETTINGS} from "@/modules/security/services/SecurityService";
import tools from "@/modules/common/utils/tools";

export default {
  name: "navbar",
  components: { LanguageSwitcher },
  data() {
    return {
      selectedProject: this.$route.params.projectId,
      projects: [],
    }
  },
  methods: {
    hasPermission(permission) {
      return permission == PERM_FREE || store.getters.getInstancePermissions.indexOf(permission) >-1
    },
    goToUserHistory() {
      this.$router.push(`/${this.$store.getters.getInstance}/user-history`)
    },
    goToSettings() {
      this.$router.push(`/${this.$store.getters.getInstance}/settings/my-profile`)
    },
    goToLanding(){
      const url = this.$store.getters.getInstanceAvatarDestinationUrl
      if(url.charAt(0) === "/"){
        if(this.$route.fullPath !== url){
          this.$router.push(url)
        }
      } else {
        tools.goToExternalUrl(url)
      }
    },
    goToMyInstances() {
      this.$router.push(`/settings/my-instances`)
    },
    pushProjectRoute(selectedProject){
      this.$router.replace(`/`)
      this.$router.replace(`/${this.$store.getters.getInstance}/projects/${selectedProject}`)
    },
    initializeLists() {
      ProjectService.getSlimProjects().then(response => {
        response.data.forEach(project => {
          this.projects.push({
            text: project.project_name,
            value: project.id,
            path: `/${this.$store.getters.getInstance}/projects/${project.id}`
          })
        })
      })
    },
    canViewMyInstances() {
      return this.botPermissions.indexOf(PERM_FREE) > -1
    },
      // Log the user out
    logout() {
      console.log(`logging out with redirect ${window.location.origin}`)
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  computed:
  mapState({
    fullName:state=>state.User.user.fullName,
    currentInstance: function() {return this.$store.getters.getInstance},
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    askRoute() {
      let url = this.$store.getters.getInstanceAvatarDestinationUrl
      if (url == undefined) {
        url = `/${this.$store.getters.getInstance}/my-apps/knowledge/ask`
      }
      return url
    },
    is_desktop: function() {
      const isDesktop = window.matchMedia("only screen and (min-width: 1024px)")
      return isDesktop.matches ? true : false
    },
    canViewSettings() {
      return this.botPermissions.indexOf(PERM_MODIFY_SETTINGS) > -1
    },
    multipleInstancesAssociated() {
      return this.$store.getters.getCurrentUser.bots.length > 1
    }
  }),
  beforeUpdate() {
    this.selectedProject = ''
    this.selectedProject = this.$route.params.projectId
  },
  watch: {
    '$route' () {
      if(this.$route.name == 'Project') {
        this.initializeLists()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.mobile-logout-button {
  position: absolute;
  bottom: 40px;
  right: 0px;
  width: 80% !important;
  left: 0;
}
.navbar-projects {
    max-height: 300px;
    overflow-y: auto;
    width: 60%;
    border: transparent;
    font-size: 16px;
    padding-top: 5px;
}

.navbar-projects:focus {
  border-color: var(--viridian);
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 31, 0.22);
}

.my-projects-link {
  color: var(--viridian);
}

.my-projects-link:hover,.my-projects-link:focus, .my-projects-link:visited, .my-projects-link:active, .my-projects-link.my-projects-link:focus-within {
  color: var(--viridian);
  outline: none;
}

.fz-14 {
  margin-left:8px;
}

.ml-25 {
  margin-left:35px;
}
.ml-35 {
  margin-left:35px;
}

.margin {
  -webkit-user-select: none;
  margin: auto;
}
.notification-dot {
  background-color: #dc3545;
  -moz-border-radius: 40%;
  -webkit-border-radius: 40%;
  border-radius: 40%;
  left: 20px;
  position: relative;
  top: -10px;
  padding: 0 3px;
}
.icon-empty {
  background: white;
  border-color: white;
  color: black;
}
.navbar-icon:hover, .navbar-icon:active, .navbar-icon:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus{
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.navbar{
  padding: 0.5rem 1.1rem;
}

.instance-image-navbar {
    min-width: 55px;
    min-height: 39px;
    border-radius: 10px;
    height: 41px;
    width: auto;
}

.image-left-container {
  margin-left: -0.30rem;
}

@media (min-width: 320px) and (max-width: 1024px) {

  .mobile-drawer-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    cursor: pointer;
  }

  ::v-deep .b-sidebar {
    width: 80%;

    .b-sidebar-body {
      padding: 0px 20px 20px 20px;
    }

  }

  .mobile-picture {
    max-width: 120px !important;
    width: 100% !important;
  }
  div.row {
    align-items: center;
    justify-content: center;

    a {
      width: 100%;
      margin-left: 0px;
    }
  }
  .my-projects-link {
    display: none;
  }

  .menu-mobile-icon {
    filter: brightness(0.1);
    display: block;
  }

   .menu-mobile-icon-profile {
    display: block;
    margin: 1rem auto 0rem auto;
  }

  .menu-mobile-text {
    margin-bottom: 0;
    font-size: 17px;
    margin-left: 16px;
    color: #1a1a1a;
  }

  div.justify-evenly {
    justify-content: space-evenly;

    a {
      width: auto;
    }
  }
  
  }

</style>