import {PERM_VIEW_LIBRARY} from '@/modules/security/services/SecurityService'
import {authGuard} from "@/auth/authGuard";

export default [
  {
    path: '/:bot/library/',
    name: 'Library',
    meta: { requiresAuth: true, permission: PERM_VIEW_LIBRARY , title: 'Sorcero Library'},
    beforeEnter: authGuard,
    component: () => import('../views/Library.vue')
  },
  {
    path: '/:bot/document/:id',
    name: 'Document',
    meta: { requiresAuth: true, permission: PERM_VIEW_LIBRARY },
    beforeEnter: authGuard,
    component: () => import('../views/Document.vue')
  },
  {
    path: '/document/:bot/:docId',
    name: 'DocumentLink',
    meta: { requiresAuth: true , permission: PERM_VIEW_LIBRARY },
    component: () => import('@/modules/common/views/RedirectCuAppLinks.vue')
  },
  {
    path: '/:bot/document-by-tags/:tags',
    name: 'DocumentByTags',
    meta: { requiresAuth: true, permission: PERM_VIEW_LIBRARY },
    beforeEnter: authGuard,
    component: () => import('../views/Document.vue')
  }
]