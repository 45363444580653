import Vue from 'vue'
import VueRouter from 'vue-router'

import LibraryRoutes from '@/modules/library/router'
import SettingsRoutes from '@/modules/settings/router'
import IngestionRoutes from '@/modules/projects/router'
import RecipeRoutes from '@/modules/recipeLibrary/router'
import UtilitySetsRoutes from "@/modules/utilitySets/router"
import LanguageInteligenceRoutes from "@/modules/languageInteligence/router"
import MedicalGuidelineManagementRoutes from "@/modules/medicalGuidelineManagement/router"
import IVDRRoutes from "@/modules/ivdr/router"
import KnowledgeRoutes from "@/modules/knowledge/router"
import HowTo from "@/modules/howto/router";
import Course from "@/modules/eta/router";
import UserHistory from "@/modules/userHistory/router";
Vue.use(VueRouter)
import { authGuard } from "../auth/authGuard";
import {PERM_FREE, PERM_VIEW_APPS} from "@/modules/security/services/SecurityService";


const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: authGuard,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../modules/common/views/Home.vue')
  },{
    path: '/:bot/',
    name: 'Default',
    beforeEnter: authGuard,
    meta: { requiresAuth: true, permission: PERM_FREE },
    component: () => import(/* webpackChunkName: "about" */ '../modules/common/views/Home.vue')
  },{
    path: '/:bot/landing/',
    name: 'Landing',
    beforeEnter: authGuard,
    meta: { requiresAuth: true, permission: PERM_FREE },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../modules/common/views/Landing.vue')
  },{
    path: '/:bot/unauthorized/',
    name: 'unauthorized',
    beforeEnter: authGuard,
    meta: { requiresAuth: true, permission: PERM_FREE },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../modules/common/views/Unauthorized.vue')
  },{
    path: '/:bot/my-apps',
    name: 'My Applications',
    beforeEnter: authGuard,
    meta: {requiresAuth: true, permission: PERM_VIEW_APPS},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../modules/common/views/MyApps.vue')
  }
]

const routes = baseRoutes.concat(LibraryRoutes,SettingsRoutes,IngestionRoutes,RecipeRoutes,UtilitySetsRoutes,
  LanguageInteligenceRoutes, MedicalGuidelineManagementRoutes, IVDRRoutes, KnowledgeRoutes, HowTo, Course,
  UserHistory)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
