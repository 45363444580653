import {PERM_FREE} from "@/modules/security/services/SecurityService";
import {authGuard} from "@/auth/authGuard";

export default [
  {
    path: '/:bot/training/',
    name: 'Training',
    meta: { 
      permission: PERM_FREE,
      isEtaBot: true 
    },
    beforeEnter: authGuard,
    component: () => import('../views/Training.vue')
  },
  {
    path: '/:bot/training/:courseName',
    meta: { 
      permission: PERM_FREE,
      isEtaBot: true
    },
    beforeEnter: authGuard,
    name: 'Course',
    component: () => import('../views/Course.vue')
  }
]