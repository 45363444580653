<template>
  <div class="side-bar-base">
    <b-nav  vertical>
      <router-link v-slot="{href, route, navigate, isActive}"
                   class="side-bar-item" :to="`/${this.$store.getters.getInstance}/how-to`">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_howto.svg">
          <p class="side-bar-item-title openSans-400 fz-12">{{ $t('general.sidenav.howto') }}</p>
        </a>
      </router-link>
      <router-link v-slot="{href, route, navigate, isActive}"
                    class="side-bar-item" :to="'/' + this.$store.getters.getInstance + '/projects'">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_myprojects.svg">
          <p class="side-bar-item-title openSans-400 fz-12">My Projects</p>
        </a>
      </router-link>
      <router-link v-slot="{href, route, navigate, isActive}"
                    class="side-bar-item" :to="'/' + this.$store.getters.getInstance + '/library'">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_mycontent.svg">
          <p class="side-bar-item-title openSans-400 fz-12">My Content</p>
        </a>
      </router-link>
      <router-link v-slot="{href, route, navigate, isActive}"
                   class="side-bar-item" :to="'/' + this.$store.getters.getInstance + '/my-apps'">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_myapps.svg">
          <p class="side-bar-item-title openSans-400 fz-12">
            {{ $t('myApps.title') }}
          </p>
        </a>
      </router-link>

      <router-link v-slot="{href, route, navigate, isActive}" v-if="canDisplayShortcut"
        class="side-bar-item" :to="askRoute">
        <a :class="{ 'router-link-active router-link-active': isActive }"
           :href="href" @click="navigate">
          <img class="side-bar-icon ask-icon" :src="'/assets/images/icon_knowledge_ask.svg'">
          <p class="side-bar-item-title openSans-400 fz-12">
            {{ $t('general.navbar.ask') }}
          </p>
        </a>
      </router-link>

      <router-link v-slot="{href, route, navigate, isActive}"
                    class="side-bar-item" :to="'/' + this.$store.getters.getInstance + '/utility-sets'">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_utilitysets.svg">
          <p class="side-bar-item-title openSans-400 fz-12">{{ $t('utilitySets.title') }}</p>
        </a>
      </router-link>
      <router-link v-slot="{href, route, navigate, isActive}"
                    class="side-bar-item" :to="'/' + this.$store.getters.getInstance + '/recipe-library'">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_recipelibrary.svg">
          <p class="side-bar-item-title openSans-400 fz-12">{{ $t('recipeLibrary.title') }}</p>
        </a>
      </router-link>

      <router-link v-slot="{href, route, navigate, isActive}"
                   class="side-bar-item" :to=" `/${this.$store.getters.getInstance}/dashboard`">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-exact-active router-link-active': isActive}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_dashboard.svg">
          <p class="side-bar-item-title openSans-400 fz-12">{{ $t('general.sidenav.dashboard') }}</p>
        </a>
      </router-link>

      <router-link v-slot="{href, route, navigate, isActive}"
        class="side-bar-item" :to="`/${this.$store.getters.getInstance}/settings`">
        <a v-show="hasPermission(route.meta.permission)"
           :class="{ 'router-link-active router-link-active': isActive || $router.currentRoute.path.includes('/settings')}"
           :href="href" @click="navigate">
          <img class="side-bar-icon" src="/assets/images/icon_leftnav_settings.svg">
          <p class="side-bar-item-title openSans-400 fz-12">
            {{ $t('settings.menu.tab.settings.title') }}
          </p>
        </a>
      </router-link>
    </b-nav>
  </div>
</template>
<script>
import store from '@/store'
import {PERM_DISPLAY_ASK_SHORTCUT, PERM_FREE} from '@/modules/security/services/SecurityService'
import tools from "@/modules/common/utils/tools";

export default {
  name: 'SideNav',
  props: {
    active: String
  },
  methods: {
    hasPermission(permission) {
      return permission == PERM_FREE || store.getters.getInstancePermissions.indexOf(permission) >-1
    },
    goToAsk() {
      let url = this.$store.getters.getInstanceAvatarDestinationUrl
      if (url == undefined) {
        url = `/${this.$store.getters.getInstance}/my-apps/knowledge/ask`
        this.$router.push(url)
      } else {
        if(url.charAt(0) === "/"){
          if(this.$route.fullPath !== url){
            this.$router.push(url)
          }
        } else {
          tools.goToExternalUrl(url)
        }
      }
    }
  },
  computed: {
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    canDisplayShortcut() {
      return this.botPermissions.indexOf(PERM_DISPLAY_ASK_SHORTCUT) > -1
    },
    askRoute() {
      let url = this.$store.getters.getInstanceAvatarDestinationUrl
      if (url == undefined) {
        url = `/${this.$store.getters.getInstance}/my-apps/knowledge/ask`
      }
      return url
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.nav.flex-column {
  position: sticky;
  z-index: 1020;
  top: 0px;
}
.ask-icon {
  filter: grayscale(1) brightness(2);
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
