import Repository from '@/modules/common/utils/Repository.js'
import store from '@/store'
import {SET_CURRENT_INSTANCE} from "@/modules/security/store/actions/PermissionsActions";

export const PERM_FREE = "PERM_FREE";
export const PERM_VIEW_DASHBOARD = "PERM_VIEW_DASHBOARD";
export const PERM_VIEW_ISSUES = "PERM_VIEW_ISSUES";
export const PERM_MODIFY_ISSUES = "PERM_MODIFY_ISSUES";
export const PERM_VIEW_LIBRARY = "PERM_VIEW_LIBRARY";
export const PERM_MODIFY_LIBRARY = "PERM_MODIFY_LIBRARY";
export const PERM_VIEW_QA = "PERM_VIEW_QA";
export const PERM_MODIFY_QA = "PERM_MODIFY_QA";
export const PERM_MODIFY_SETTINGS = "PERM_MODIFY_SETTINGS";
export const PERM_VERIFY = "PERM_VERIFY";
export const PERM_MODIFY_USER_SETTINGS = "PERM_MODIFY_USER_SETTINGS";
export const PERM_VIEW_APPS = "PERM_VIEW_APPS";
export const PERM_VIEW_GENERAL_INFO = "PERM_VIEW_GENERAL_INFO";
export const PERM_VIEW_PROJECTS = "PERM_VIEW_PROJECTS";
export const PERM_OVER_CONTENT_PROTECT = "PERM_OVER_CONTENT_PROTECT";
export const PERM_DISPLAY_ASK_SHORTCUT = "PERM_DISPLAY_ASK_SHORTCUT";
export const PERM_EXTERNAL_ASK_PAGE = "PERM_EXTERNAL_ASK_PAGE";

export default {
  getUser() {
    return Repository.dbService.get('user/get_user_data',{})
  },
  getPermissions() {
    var parameters = {}
    parameters['bot_name'] = store.getters.getInstance
    return Repository.dbService.get('user/get_permissions', {params: parameters})
  },
  chekRoutePermission(to, next, redirectRoute) {
    // With PERM_FREE we don't check the permissions
    if (to.query.code) {
      // the code parameter comes from auth0, we skip the permission
      return next()
    }
    let permission = to.meta.permission
    console.log(permission)
    let botName = to.params.bot
    if (permission == PERM_FREE) {
      store.dispatch(SET_CURRENT_INSTANCE, { instanceName: botName, path: to.path })
      return next()
    }

    let bots = store.getters.getCurrentUser.bots
    if (bots) {
      // Set current instance by name or firts if user has only one bot
      store.dispatch(SET_CURRENT_INSTANCE, { instanceName: botName, path: to.path })

      let botData = null
      let defaultRoute = `/${botName}/unauthorized/`
      if (bots.length === 1) {
        // Find unique user bot
        botData = bots.find((elem, index) => elem && index == 0)
        // Set default route for user with a single bot
        defaultRoute = `/${botData.bot_name}/`
      } else {
        // Find bot by bot in route
        botData = bots.find(bot => bot.bot_name == botName)
      }

      if (!botData) {
        console.log('user doesnt have this bot')
        return next(redirectRoute ? redirectRoute : '/settings/my-instances')
      } else {
        if (botData.permissions.indexOf(permission) == -1) {
          console.log('doesnt have permission')
          return next(redirectRoute ? redirectRoute : defaultRoute)
        } else {
          return next()
        }
      }
    }
    return next()
  }
}